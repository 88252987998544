import DynamicPage from '@xpedeo/core/dist/components/DynamicPage'

export default DynamicPage.extend({
  computed: {
    dynamicPage () {
      switch (this.pageData.Class) {
        case 'XpSplashScreen':
          return 'XpSplashScreenPage'
        case 'XpSelectLanguage':
          return 'XpSelectLanguagePage'
        case 'XpHome':
          return 'XpHomePage'
        case 'XpMenu':
          return 'XpMenuPage'
        case 'XpMap':
          return () => import(/* webpackChunkName: "xp-map-page" */ './pages/XpMapPage')
        case 'XpMultipleChoice':
          return () => import(/* webpackChunkName: "xp-panorama-entry" */ './pages/XpMultipleChoicePage')
        case 'XpNfc':
          return () => import(/* webpackChunkName: "xp-panorama-entry" */ './pages/XpNfcPage')
        case 'XpNumericInput':
          return 'XpNumericInputPage'
        // case 'XpFlipbook':
        //   return () => import(/* webpackChunkName: "xp-panorama-entry" */ './pages/XpFlipbookPage')
        // case 'XpPseudoPanorama':
        //   return () => import(/* webpackChunkName: "xp-panorama-entry" */ './pages/XpPseudoPanoramaPage')
        case 'XpError':
          return 'XpErrorPage'
        default:
          return 'XpPage'
      }
    }
  }
})
